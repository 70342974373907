//
// This file contains application-specific styles.
//

// import common styles
@import 'common/styles.scss';

// sticky, on top footer
footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 900;
}
